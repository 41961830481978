//== Typography custom styling.
@import url("https://use.typekit.net/hoi7emi.css");

p {

  &.lead {
    font-size: 1.6em;
    font-weight: 500;
  }
}

strong {
  font-size: 500;
}


h1 {
  font-size: 2rem;
  margin: 0 0 1em;
  font-weight: 300;

  @media #{$medium-and-up} {
    font-size: 3rem;
  }
}


dl {


  &.dl-horizontal {
    background-color: $white-color;
    padding: 1rem;
    border-radius: 15px;

    @media #{$medium-and-up} {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;

      div {
        float: left;
        margin: 0 2rem 0;
      }
    }
  }

  dt {
    margin-bottom: 0.3rem;
    font-size: 0.9rem;
    font-weight: 500;
  }

  dd {
    margin: 0 0 1rem;
    word-break: break-all;
    font-size: 1.1rem;

    &.word-break-normal{
      word-break: normal;
    }

    span.smaller-text {
      font-size:0.8rem;
    }
  }
}

.group-lock-warning {
  font-size:0.8rem;
}
