.login-page {
  .row {
    margin-bottom: 0;
  }
  margin: 0;
}
#login-wrapper {
  background-color: $white-color;

  @media #{$small-and-down} {
    position: fixed;
    height: 100%;
  }

  @media #{$medium-and-up} {
    border-radius: 40px;
  }
  @media #{$extra-large-and-up} {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
  }

  .margin-bottom-15 {
    margin-bottom:15px;
  }

  & > .col {
    padding: 2rem;

    @media #{$medium-and-up} {
      height: 500px;
    }
    @media #{$large-and-up} {
      padding: 3rem;
    }
    @media #{$extra-large-and-up} {
      padding: 4rem;
    }
  }

  .login-description {
    display:flex;
    flex-direction: column;
    justify-content: center;
    background-image: url('/static/img/login-background.png');
    font-family: "termina","Helvetica","Arial",sans-serif;
    text-transform: uppercase;
    color: #333;
    padding: 0 3rem;

    .logo-margin {
      margin-top: -25px;
      margin-bottom: 25px;
    }

    h1 {
      color: #333;
      text-align: center;
      font-size: 2rem;
      font-weight: 900;
      margin: 0 auto 10px auto;
    }

    h2 {
      color: #333;
      font-size: 1.5rem;
      font-weight: 700;
      margin: 0 auto 10px auto;
    }

    @media #{$medium-and-up} {
      border-radius: 40px 0 0 40px;
    }

    @media #{$small-and-down} {
      padding: 50px 2rem 50px 2rem;

      h1{
        font-size: 1.75rem;
      }
      h2{
        font-size: 1.5rem;
      }

    }

    .text {
      font-size: 1.4rem;
      line-height: 1.4em;
      vertical-align: middle;

      @media #{$medium-and-up} {
        margin: 20% 0 0;
      }

      span {
        display: block;
        color: $primary-color-xlight;
        font-size: 1.7em;
        font-family: 'Dancing Script', cursive;
        line-height: 1.1em;
        margin: 0.2em 0;
      }
    }

    a {
      color: $white-color;
      text-decoration: underline;
      font-size: 0.9em;
    }
  }

  .login-form {
    display:flex;
    flex-direction: column;
    justify-content: center;

    h2 {
      color: $secondary-color;
      font-size: 2rem;
      text-align: center;
      margin-bottom: 2em;
    }

    form {
      padding: 0;
    }
  }
}

