//== Materialize side navigation custom styling.

.sidenav {
  background-color: $white-color;
  width: 250px;

  @media #{$large-and-up} {
    box-shadow: none;
  }

  .sidenav-title {
    height: 56px;
    background: $secondary-color;
    color: $white-color;
    padding: 0.5rem 1rem;
    font-size: 2rem;

    .max-width-logo{
      max-width:80%;
    }

    @media #{$medium-and-up} {
      height: 64px;
    }
  }

  li {
    &.language-li {
      position:fixed;
      bottom: 60px;

      a {
        font-size: 13px;

        &:hover {
          background-color: $white-color;
        }

        i {
          margin: 0 5px 0 0;
        }

      }
    }

    & > a {
      padding: 0 1em;

      @media #{$medium-and-up} {
        font-size: 15px;
      }

      & > i.material-icons {
        font-size: 1.8em;
      }
    }
  }
}

/* Add some left padding to main elements of the page on larger screens to make room for the fixed side nav. */
body {

  & > header, & > main, & > footer {
    margin-left: 250px;

    @media #{$medium-and-down} {
      margin-left: 0;
    }
  }
}
