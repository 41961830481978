//== Styling for the sticker
.sticker {
  height: 4.5em;
  width: 4.5em;
  background: $primary-color;
  position: absolute;
  border-radius: 5px;
}
.sticker:before {
  height: 4.5em;
  width: 4.5em;
  background: $primary-color;
  content:"";
  position: absolute;
  /* Rotate */
  transform: rotate(30deg);
  border-radius: 5px;
}
.sticker:after {
  height: 4.5em;
  width: 4.5em;
  background: $primary-color;
  content:"";
  position: absolute;
  /* Rotate */
  transform: rotate(-30deg);
  border-radius: 5px;
}
.new {
  background-color: $primary-color;
  color: #fff;
  font: 0.85em 'Archivo Narrow', sans-serif;

  letter-spacing: .09em;
  font-weight: bold;
  position: absolute;
  left: .1em;
  top: 2em;
  transform: rotate(-15deg);
  user-select: none;
}
.new-sticker {
  margin: 0;
  position: relative;
  display: inline-block;
  top: -3.5em;
  left: -2.5em;
  border: 1px solid white;
  transition: transform .3s ease-in-out;

  &:hover {
    transform: scale(1.2) rotate(15deg) translate(0, -12px);
  }
}
