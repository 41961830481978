//== Project specific classes.

//== Positionning
.centered {
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  margin: 0 auto;

  @media #{$small-and-down} {
    position: inherit;
    top: 0%;
    left: 0;
    right: 0;
    transform: translateY(0%);
    margin: 45px auto 75px auto;
  }
}

.illustration {
  display: none;
  position: absolute;
  bottom: 10px;
  right: 10%;
  width: 30vh;
  text-align: right;
  max-width: 450px;


  @media #{$medium-and-up} {
    display: block;
    width: 40vh;

    #student-list & {
      width: 25vh;
    }
  }

   @media #{$large-and-up} {
    #student-list & {
      width: 30vh;
    }
  }

  @media #{$extra-large-and-up} {
    width: 60vh;
  }

  @media #{$extra-extra-large-and-up} {
    #student-list & {
      width: 40vh;
    }
  }
}

.aligned-icon {
  vertical-align: bottom;
}

.icon-danger {
  color: $error-color;
  vertical-align: sub;
}

.icon-warning {
  color: $warning-color;
  vertical-align: bottom;
}

.image {
  display: block;
  width: 100%;
  height: 150px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

//== Sizing
.w-50 {
  width: 50%;
}
.w-75 {
  width: 75%;
}
.w-100 {
  width: 100%;
}


//== Clearing
.clearfix {

  &::after {
    content: "";
    display: block;
    clear: both;
  }
}

.row-breathe .col {
  margin-bottom: 1em;
}


//== Displaying
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

//== Text Colors
.text-primary {
  color: $primary-color !important;
}
.text-secondary {
  color: $secondary-color !important;
}
.text-danger {
  color: $error-color !important;
}


//== Background Colors
.bg-primary {
  background-color: $primary-color !important;
}
.bg-secondary {
  background-color: $secondary-color !important;
}
.bg-danger {
  background-color: $error-color !important;
}


//== Components
// stats
ul.stats {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 1.2rem;

  li {
    display: inline-block;

    & + li {
      margin-left: 1em;
    }

    a {
      text-decoration: none;

      .material-icons {
        margin-left: 0.3em;
        font-size: 1.2em;
        vertical-align: text-bottom;
      }
    }
  }
}

// Feature Discovery
.feature-discovery {
  position: absolute;
  bottom: 0;
  left: -1rem;
  transform: translateX(-100%) translateY(50%);
  text-align: right;

  p {
    margin: 0;
    font-size: 1.1rem;
    color: #888;
  }

  img + p {
    margin-right: 2em;
  }
}

.dt-horizontal {
  float: left;
  margin: 0 2rem 0;

  @media #{$small-and-down} {
    float: none;
    margin: 0;
  }
}
blockquote {
  text-align: justify;
  margin: 15px 0;
  padding: 5px 0 5px 1.5rem;
  border-left: 5px solid $primary-color-light;
}

.switch {
  display: inline;
  padding-right: 15px;
  margin-right: 25px;

  @media #{$small-and-down} {
    padding-bottom:15px;
    display:inherit;
    margin-right: 0;
  }
}

.lever {
  background-color: #bcc8c6 !important;
}

.lever:after {
  background-color: $secondary-color !important;
}

.icon-with-text-under {
  display: inline-block;
  text-align: center;
  transition: 0.3s ease;

  &.active {
    color: $secondary-color;
  }
}

.switch-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;

  @media #{$small-and-down} {
    align-items: center;
    flex-direction: column;
  }
}

.confirmation-icon {
  vertical-align: middle;
  user-select: none;
}

.audio-container {
  padding-top: 1rem;
  @media #{$small-and-down} {
    audio {
      width:100%
    }
  }
}

//== Media Query Classes
.hide-on-large-and-down {
  @media #{$large-and-down} {
    display: none !important;
  }
}

// Bullet lists displayed as HTML messages
.display-bullet-list ul li, .display-bullet-list ul {
  list-style-type: disc;
  margin-left: 10px; }

.flex-on-small-and-down {
  @media #{$small-and-down} {
    display: flex;
  }
}

.right-but-centered-on-small-and-down {
  float: right;
  @media #{$small-and-down} {
    margin: auto;
    float: inherit;
    display: block;
  }
}

.link-padding {
  padding-right: 1.85rem !important;
  margin-top: 1rem !important;

  @media #{$small-and-down} {
    padding: 0 !important;
  }
}
