//== Project Variables.


// Colors
// ==========================================================================

$primary-color: rgb(90,135,175);
$primary-color-xlight: lighten($primary-color, 30%);
$primary-color-light: lighten($primary-color, 15%);
$primary-color-dark: darken($primary-color, 15%);

$secondary-color: rgb(87,173,163);
$secondary-color-xlight: lighten($secondary-color, 30%);
$link-color: $primary-color;
$white-color: rgb(249,249,250);
$gray-base-color: rgb(157,159,160);
$gray-xlight-color: lighten($gray-base-color, 32%);
$gray-light-color: lighten($gray-base-color, 15%);
$gray-dark-color: darken($gray-base-color, 15%);
$gray-xdark-color: darken($gray-base-color, 30%);
$warning-color: rgb(246,174,45);


// Global
// ==========================================================================

// Custom Media Query Breakpoint
$large-and-down: "only screen and (max-width :1200px)";
$extra-extra-large-and-up: "only screen and (min-width : 1500px)";


// Buttons
// ==========================================================================

// Shared styles
$button-background-focus: lighten($primary-color, 4%);
$button-secondary-background-focus: lighten($secondary-color, 4%);
// Raised buttons
$button-raised-background: $primary-color;
$button-secondary-raised-background: $secondary-color;
$button-raised-background-hover: lighten($button-raised-background, 5%);
$button-secondary-raised-background-hover: lighten($button-secondary-raised-background, 5%);
// Floating buttons
$button-floating-background: $primary-color;


// Grid
// ==========================================================================

$num-cols: 12;
