//== Materialize cards custom styling.

.card {
  border-radius: 0 0 1rem 1rem;
  box-shadow: 0px 14px 23px 2px rgba(90, 135, 175, 0.25);

  .card-image {

    .clickable {
      position: absolute;
      width: 100%;
      height: 100%;

      @media #{$small-and-down} {
        position: unset;
      }
    }

    &.fixed-landscape {

      @media #{$medium-and-up} {
        height: 250px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
      }
      @media #{$extra-large-and-up} {
        height: 200px;
      }
    }

    .img-replacement {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      margin: 0;
      transform: translateY(-50%);
      font-weight: 300;
      line-height: 1.2em;
      font-size: 1.4em;
      text-align: center;
    }
  }

  .card-content {

    .card-title {
      line-height: 1.5em;
      font-weight: 500;
      font-size: 1.2em;

      @media #{$extra-large-and-up} {
        font-size: 1.1em;
      }
    }

    .card-footer {
      display: block;
      line-height: 1.5em;
      font-weight: 500;
      font-size: 1.2em;
      text-align: right;
      margin-top: 1em;

      a {
        color: #6C6C6D;

        .material-icons {
          margin-right: 0.3em;
          font-size: 1.2em;
          vertical-align: text-bottom;
        }
      }

      @media #{$extra-large-and-up} {
        font-size: 1.1em;
      }
    }
  }
}

.card-panel {
  border-radius: 1rem;
  box-shadow: 0px 14px 23px 2px rgba(90, 135, 175, 0.25);
  .row {
    margin-bottom: 0;
  }

  .card-icon {

    @media #{$medium-and-up} {
      width: 5rem;
      float: left;
    }

    .material-icons {
      font-size: 5rem;

      @media #{$medium-and-up} {
        font-size: 5rem;
      }
      @media #{$extra-large-and-up} {
        font-size: 6rem;
      }
    }
  }

  .card-content {
    @media #{$medium-and-up} {
      margin-left: 7rem;
    }
    @media #{$extra-large-and-up} {
      margin-left: 9rem;
    }

    .card-title {
      text-align: left;
      font-size: 1.6rem;
      margin: 0 0 1em;
    }

    .card-footer {
      display: block;
      text-align: right;
    }

    p {
      font-size: 1.1rem;
    }
  }
}

.tos-margin{
  @media #{$medium-and-up} {
      margin-right: 7rem;
  }
  @media #{$extra-large-and-up} {
    margin-right: 9rem;
  }
}

.card-shadow {
  box-shadow: 0px 14px 23px 2px rgba(90, 135, 175, 0.25) !important;
}

.card-special-content {
  margin: 20px auto 0 auto;
  padding: 10px 25px;
  border-radius: 1rem;
  background-color: $gray-xlight-color;
  color: #6e6e6e;
}

.fit-content-except-on-small {
  width: fit-content;

  @media #{$small-and-down} {
    width: 100%;
  }
}
