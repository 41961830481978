//== Materialize navbar custom styling.

nav {
  box-shadow: none;
  
  .nav-wrapper {

    .brand-logo {

      @media #{$medium-and-down} {
        left: auto;
        -webkit-transform: none;
        transform: none;
      }
    }

    ul {

      li {
        position: relative;

        a {
          white-space: nowrap;

          i.material-icons {
            display: inline-block;
            vertical-align: top;
          }
        }
      }
    }
  }

  .margin-top-fix {
    margin-top:5px;
    @media #{$small-and-down} {
      margin-top:2px;
    }
  }

}
