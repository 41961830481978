form {
  padding: 1rem;
  background-color: $white-color;
  border-radius: 15px;

  @media #{$medium-and-up} {
    padding: 2rem;
  }

  &.form-no-ul {
    ul {
      display: none;
      &.errorlist {
        display: inherit;
        padding-bottom: 15px;
        li {
          color: #ef7575;
        }
      }
    }
  }

  p.confirm {
    font-size: 1.1rem;
  }

  fieldset {
    padding: 0 0 0 1rem;
    margin: 0 0 2rem -1rem;
    border: none;
    border-left: 2px solid $secondary-color;

    .row {
      margin-bottom: 0;
    }
  }

  span {
    &.helptext {
      position: relative;
      min-height: 18px;
      display: block;
      font-size: 13px;
      color: rgba(0, 0, 0, 0.54);
    }
  }

  .input-field {

    & > label {
      font-size: 1.1rem;
    }

    & .select-wrapper + label {
      font-size: $label-font-size;
    }

    .helper-text {
      font-size: 0.9rem;
      &.help-blue{
        color:#5a87af;
      }
    }

    .errorlist {
      margin: 0;
      font-size: 0.9rem;

      li {
        color: $input-error-color;
      }
    }

    & + button[type='submit'] {
      margin-top: 1em;
    }

    select {
      display: block;
      height: 0;
      width: 0;
      position: absolute;
      padding: 0;
    }

    .processing {
      background-color: $gray-base-color;
    }
  }

  footer {
    margin-top: 2rem;
    text-align: right;
  }

  .autocomplete-content{
    &.dropdown-content{
      top:46px !important;
    }
  }

  .django-ckeditor-widget {
    width: 100%;
  }
  .cke_bottom {
    display: none;
  }
  .character-counter {
    color: #6e6e6e;
  }
  input.disabled {
    color: rgba(0, 0, 0, 0.42);
    border-bottom: 1px dotted rgba(0, 0, 0, 0.42);
  }
}

  .form-errors {
    width: fit-content;
    background: #ef7575;
    border-radius: 50px;
    padding: 10px 15px;
    margin-bottom: 20px;
    color: #fff;
    span {
      padding-right: 5px;
    }
  }

  #congratulations-comment-form {
    .character-counter {
      display: inherit;
      color: #6e6e6e;
    }
  }

  .communication-label {
    font-size: 1.1rem !important;
  }

  .confirm-form {
    width: 75%;
  }
