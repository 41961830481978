//== Materialize grid customization.

@mixin reset-offset {
  margin-left: auto;
  left: auto;
  right: auto;
}
@mixin grid-classes($size, $i, $perc) {
  &.offset-#{$size}#{$i} {
    margin-left: $perc;
  }
  &.pull-#{$size}#{$i} {
    right: $perc;
  }
  &.push-#{$size}#{$i} {
    left: $perc;
  }
}

.row {

  .col {

    // Add an additional breakpoint for XXLarge screens (>=1600px)
    @media #{$extra-extra-large-and-up} {

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.xxl#{$i} {
          width: $perc;
          @include reset-offset;
        }
        $i: $i + 1;
      }

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        @include grid-classes("xxl", $i, $perc);
        $i: $i + 1;
      }
    }
  }
}

.nav-div {
  padding: 20px !important;
  margin: 2px 0 0 0 !important;
  background-color: #f7f7f7;
  border-radius: 0 0 15px 15px;
}

.nav-div-header {
  border-radius: 15px 15px 0 0;
}


.flex-va-center {
  @media #{$medium-and-up} {
    display: flex;
    align-items: center;
    padding-top: 2em;
    padding-bottom: 2em;
  }
}

