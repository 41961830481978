//== Materialize buttons custom styling.

.btn {
  padding: 0.2rem 2rem;
  height: auto;

  &.btn-outlined:not(.btn-secondary) {
    background-color: transparent;
    border: 1px solid $button-raised-background;
    color: $button-raised-background;

    &:hover {
      background-color: $button-raised-background-hover;
      border: 1px solid $button-raised-background-hover;;
      color: $white-color;
    }
    &:focus {
      background-color: darken($button-raised-background, 10%);
      color: $white-color;
    }
  }
}

.btn, .btn-large, .btn-small, .btn-floating, .btn-flat, .btn-border {
  border-radius: 30px;
  color: $white-color;
  white-space: nowrap;
  text-align: left;

  i.left {

    @media #{$small-and-down} {
      margin-right: 0;
    }
  }
}

.card .fixed-action-btn {
  position: absolute;
  bottom: 0;
  transform: translateY(50%);

  ul {
    right: 44px;  // Avoid sub-actions to overflow card border when a card is very small.
  }
}

.btn-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1em;
  border-radius: 50%;
  text-align: center;
  color: $white-color;
  font-size: 1.6rem;
  vertical-align: text-bottom;

  i {
    font-size: 0.9em;
    line-height: 1.1em;
  }
}

.btn-flat {
  color: $link-color;
}

.btn-border {
  background: white;
  height: auto;
  text-transform: uppercase;
  box-shadow: none;
  outline: 1px solid $button-raised-background;
  outline-offset: -1px;
  // border: 1px solid $button-raised-background;
  color: $button-raised-background;

  &:hover {
    background-color: lighten($button-raised-background, 40%);
    outline: 1px solid $button-raised-background-hover;
    color: $button-raised-background-hover;
    box-shadow: none;
  }
  &:focus {
    background-color: lighten($button-raised-background, 40%);
    outline: 1px solid $button-raised-background-hover;
    color: $button-raised-background-hover;
    box-shadow: none;
  }
}

.btn-secondary {
  background-color: $button-secondary-raised-background;

  &:hover {
    background-color: $button-secondary-raised-background-hover;
  }
  &:focus {
    background-color: darken($button-secondary-raised-background, 10%);
  }

  &.btn-outlined {
    background-color: transparent;
    border: 1px solid $button-secondary-raised-background;
    color: $button-secondary-raised-background;

    &:hover {
      background-color: $button-secondary-raised-background-hover;
      color: $white-color;
    }
    &:focus {
      background-color: darken($button-secondary-raised-background, 10%);
      color: $white-color;
    }
  }
}

.small-fab-fix {
  &.fixed-action-btn.direction-left ul li, &.fixed-action-btn.direction-right ul li {
    margin: 0 10px 0 0;
  }
}

.edit-button-top {
  vertical-align: top;
  font-size: 1.4rem;
}

.disabled {
  pointer-events: none;
}

.button-padding {
  padding: 1.5rem;
  height: auto;

  @media #{$small-and-down} {
    padding: 1rem 0.5rem;
    i {
      margin-right: 0;
    }
  }
}
