.dummy-responsive {
  overflow: auto;
}

table {
  th[role=columnheader]:not(.no-sort) {
    cursor: pointer;
  }

  th[role=columnheader]:not(.no-sort):after {
    content: '';
    float: right;
    margin-top: 7px;
    border-width: 0 4px 4px;
    border-style: solid;
    border-color: #404040 transparent;
    visibility: hidden;
    opacity: 0;
    -ms-user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }

  th[aria-sort=ascending]:not(.no-sort):after {
    border-bottom: none;
    border-width: 4px 4px 0;
  }

  th[aria-sort]:not(.no-sort):after {
    visibility: visible;
    opacity: 0.4;
  }

  th[role=columnheader]:not(.no-sort):hover:after {
    visibility: visible;
    opacity: 1;
  }

  thead {

    th {
      font-weight: 500;
      font-size: 0.9rem;
      vertical-align: bottom;
    }
  }

  tbody {

    tr {
      background-color: $white-color;

      td {
        padding: 15px 10px;

        &.actions {
          text-align: right;

          .dropdown-content {

            @media #{$medium-and-up} {
              box-shadow: none;
              background-color: transparent;
              display: inline-block;
              min-width: auto;
              opacity: 1;
              position: relative;
              left: auto;
              top: auto;
              overflow: visible;
              z-index: 0;

              & > li {
                display: inline-block;
                min-height: inherit;
                width: auto;

                & + li {
                  margin-left: 1rem;
                }

                & > a {
                  padding: 0;
                  color: $primary-color;

                  & > i {
                    margin: 0;
                  }
                }
              }
            }

            & > li > a {
              color: $primary-color;

              &.disabled {
                pointer-events: none;
                color: #9F9F9F !important;
                cursor: not-allowed;
              }

              & > i {
                margin-right: 0;
              }

              & > span {
                margin-left: 0.5rem;
                font-size: 0.9em;

                @media #{$medium-and-up} {
                  display: none;
                }

                @media #{$extra-extra-large-and-up} {
                  display: inline;
                  font-size: 1em;
                }
              }
            }
          }
        }
      }
    }
  }

  .alternate-row-color {
    tr:nth-child(even){
      background-color: #FFFFFF;
    }
  }

  tfoot {

    tr {
      border-bottom: none;

      td.actions {
        text-align: right;
      }
    }
  }
}
