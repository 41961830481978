//== Materialize toast custom styling.

.toast {
  background-color: rgba($gray-xdark-color, 0.9);
  font-weight: 400;

  &.success {
    background-color: rgba($secondary-color, 0.9);
  }

  &.error {
    background-color: rgba($error-color, 0.9);
  }

  &:not(:last-child) {
    display: none;
  }
}
