//== Materialize cards custom styling.

.use-confirm-modal, .show-modal {
  background-color: #f0f0f0;
  font-size: 1.1rem;
  overflow: hidden;
  border-radius: 15px;


  .modal-footer {
    margin-bottom: 5px;
    background-color: #f0f0f0;
  }

  @media #{$small-and-down} {
    font-size: 0.9rem;
    height: 100%;
    width: 100%;
    height: 100vh !important;
    max-height: 100%;
    top: 0 !important;
    border-radius: 0;
  }
}

.show-modal {
  height: 90%;

  @media #{$small-and-down} {
    font-size: 1rem;
  }

  dd {
    font-size: 1rem;

    @media #{$medium-and-down} {
      font-size: 1rem;
    }
  }
}

.use-confirm-modal {
  .use-confirm-text {
    @media #{$small-and-down} {
      font-size: 1.4rem;
      margin-top: 70px;
    }
  }

  .modal-footer {
    @media #{$small-and-down} {
      bottom: 0;
      position: absolute;
    }
  }
}
