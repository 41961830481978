//== Project's global styling.

body {
  background-color: $gray-xlight-color;

  ul {
    &.instruction-list{
      margin-left:25px;
      margin-top: inherit;

      li {
        list-style-type:disc;
        margin-top: 6px;
        font-size: 1.1rem;
      }
    }
  }

  .guardian-message {
    text-align: left;
    word-break: break-all;
  }

  .margin-top-20 {
    margin-top:20px;
  }

  .fix-bottom-margin {
    margin-bottom: 0;
  }

  .fab-right {
    position: inherit;
    float: right;
    margin-right: 8px;
  }

  .text-grey {
    color: #6e6e6e;
  }

  .help-small {
    font-size: 0.9rem;
    cursor: help;
  }

  #hijacked-warning-wrapper {
    overflow: hidden;
  }

  .hijacked-warning {
    margin-left: 250px;
    background-color: #ef7575;
    width: 100%;
    min-height: 48px;
    color: #fff;
    line-height: 40px;
    background-size: 80px 80px;
    background-image: -webkit-linear-gradient(-45deg, rgba(0, 0, 0, .04) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, .04) 50%, rgba(0, 0, 0, .04) 75%, transparent 75%, transparent);
    background-image: -moz-linear-gradient(-45deg, rgba(0, 0, 0, .04) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, .04) 50%, rgba(0, 0, 0, .04) 75%, transparent 75%, transparent);
    background-image: linear-gradient(135deg, rgba(0, 0, 0, .04) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, .04) 50%, rgba(0, 0, 0, .04) 75%, transparent 75%, transparent);
  span {
      padding: 2rem 1rem 2rem 2rem;
    }
    form {
      padding:0;
      background-color: #ffffff00;
      line-height: 3.3;
      button {
        font-weight: bold;
        background: $secondary-color;
        border: none;
        color: white;
        border-radius: 50px;
        padding: 10px 15px 10px 15px;
        transition: .3s ease-out;
        -webkit-tap-highlight-color: transparent;
      }
      button:hover {
        background-color: #68b5ac;
      }
    }
    form:nth-child(2){
      display:none;
    }
    @media #{$medium-and-down} {
      margin-left: 0;
    }
  }
}

main {
  padding: 2rem 1rem 6rem;

  @media #{$medium-and-up} {
    padding: 2rem 2rem 6rem;
  }

  & > header {
    position: relative;
    margin: 0 0 4rem;

    .subtitle {
      font-size: 1.1rem;
      color: #9d9fa0;
      vertical-align: text-top;
      padding-left: 3px;

      @media #{$medium-and-down} {
        padding-left: 1px;
      }
    }

    nav {
      position: absolute;
      top: -3rem;
      left: -2rem;
      width: 100%;
      height: auto;
      line-height: 2;
      padding: 0 2rem;
      background-color: transparent;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      .breadcrumb, .breadcrumb:last-child {
        color: $gray-base-color;
        font-size: 1rem;
        padding: 5px 0;
        display: inline-block;
      }

      .breadcrumb {

        &:hover {
          color: $primary-color;
        }

        &:last-child {
          font-weight: 300;
        }

        &:not(:last-child):after{
          font-family: 'Material Icons';
          content: "arrow_forward_ios";
          display: inline-block;
          margin: 0 10px 0 8px;
          color: $gray-base-color;
          font-size: 1.1rem;
          line-height: 1.8;
          vertical-align: top;
        }

        &:before {
          display: none;  // Overwrite Materialize's default
        }
      }
    }

    nav + h1 {
      margin-top: 1rem;
    }

    .actions {
      position: absolute;
      right: 0;
      top: 0;

      .btn {
        padding: 0 9px;

        @media #{$medium-and-up} {
          height: 54px;
          line-height: 54px;
          padding: 0 2rem;
        }

        i.left {

          @media #{$medium-and-down} {
            margin-right: 0;
          }
        }
      }
    }
  }

  & > section {
    &.section-margin {
      margin-top: 75px;
    }
    header {
      position: relative;

      .section-heading {
        border-bottom: 1px solid $secondary-color;
        padding: 0.7em 0;
        font-weight: 400;
        color: $secondary-color;
        text-transform: uppercase;
        font-size: 1.2rem;

        & span {
          font-size: 0.9rem;

          @media #{$medium-and-up} {
            font-size: 1.1rem;
          }
        }

        @media #{$medium-and-up} {
          font-size: 1.4rem;
        }
      }

      .section-actions {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        .btn {
          padding: 0 9px;

          @media #{$medium-and-up} {
            padding: 0 2rem;
          }
        }
      }
    }
  }
}


#main-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid $gray-light-color;

  .row {
    margin-bottom: 0
  }

  p {
    height: 56px;
    line-height: 56px;
    margin: 0;
    font-size: 0.8rem;
  }

  nav {
    background-color: $gray-xlight-color;
    a {
      height: 56px;
      line-height: 56px;
    }
  }
}

#public {
  padding-bottom: 3rem; // Make room for the footer

  @media (min-width: 1900px) {
    background-size: cover;
  }

  #branding {
    position: relative;
    margin: 0 auto;
  }

  section {

    & + section {
      margin-top: 4rem;
    }

    .section-heading {
      font-size: 1.6rem;
      margin: 1em 0 1.5em;
      text-align: center;
      color: $secondary-color;
    }
  }

  footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
    text-align: center;
    font-size: 0.9rem;
  }
}

.students-empty-text{
  max-width:100%;

   @media #{$medium-and-up} {
    max-width:60%;
  }
}

::selection {
  background: $primary-color-xlight;
}

.responsive-600 {
  width: 600px;

  .tabs {
    display:flex
  }
  .tab {
    flex-grow: 1;
  }
  iframe {
    width: 100%;
  }

  @media #{$small-and-down} {
    width: 350px;
    margin:auto;
  }
}

.span-link {
  color: #5a87af;
  cursor: pointer;
  text-decoration: underline;
  transition: .3s ease-out;

  &:hover {
    color: lighten(#5a87af, 15%);
  }
}

.recording-section {
  width: fit-content;
  width: -moz-max-content;
  padding:15px;
  border-radius: 15px;
  border: 3px solid #dfdfdf;
  @media #{$small-and-down} {
    width: 100%;
  }
}

audio {
  border-radius: 50px;
}

.clip {
  audio {
    width:100%;
  }
}

.visualizer {
  width: 100%;
  height: 54px;
  border-radius: 50px;
}

.instructions {
  background: #f0f0f0;
  padding: 15px;
  border-radius: 15px;

  p {
    margin: 0;
  }
}


.align-center-on-small-only {
  @media #{$small-and-down} {
    text-align: center !important;
  }
}

.cke_button__link {
  display: none !important;
}

.float-right-except-on-small {
  float: right;

  @media #{$small-and-down} {
    float: none !important;
  }
}

.text-right-on-small-only {
  @media #{$small-and-down} {
    text-align: right !important;
  }
}

.more-margin-top-on-small-only {
  @media #{$small-and-down} {
    margin-top: 2rem !important;
  }
}
