.public-footer {
  background-color: $primary-color;

  &:before, &:after {
    content: "";
    display: block;
    position: absolute;
    background-repeat: no-repeat;
    background-position: top left;
    background-size: contain;
  }

  &:before {
    width: 25vw;
    height: 10vw;
    top: -5vw;
    right: 78%;
    background-image: url('/static/img/forme-nuage-section-footer.svg');
  }

    &:after {
    width: 15vw;
    height: 10vw;
    top: -3vw;
    right: -4%;
    transform: scale(-1, 1);
    background-image: url('/static/img/forme-nuage-section-footer.svg');
  }
}

.card-form {
  padding: 0;
  background-color: #ffffff;

  ul:not(.errorlist) {
    display:none;
  }
}

.form-buttons {
  text-align: right;
  padding-top:15px;
}

#branding {
  padding-top:50px;
  text-align: center;

  .logo {
    width: 100%;
    max-width: 300px;
    height: auto;

    @media #{$medium-and-down} {
      height: auto;
      padding:15px;
    }
  }
}

.errorlist li {
  color: $error-color;
}

.col.title-and-text {
  padding: 0;
  h5 {
    font-size: 1.7em;
    line-height: 100%;
    @media #{$medium-and-down} {
      font-size: 1.4em;
    }
  }
  span {
    font-size: 1.1em;
  }
}

.nav-div-same-height {
  min-height: 450px !important;
  @media #{$medium-and-down} {
    min-height: 250px !important;
  }
}

.content-text {
  margin-left: 20px;

  p {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    font-size: 1.1em;
  }

  @media #{$small-and-down} {
    margin-top: 20px;
    margin-left: 0;
  }
}

.content-title {
  line-height: 1.5em;
  font-weight: 500;
  font-size: 1.5em;
  margin-top: 0;
  margin-bottom: 0;

  @media #{$medium-and-down} {
    font-size: 1.3em;
  }
}

.div-mt {
  margin-top: 20px;

  h2:nth-of-type(2n) {
    margin-top: 25px;
  }

  @media #{$medium-and-down} {
    margin-top: 5px;
  }
}

.collapsible-header {
  color: $primary-color;
  text-transform: uppercase;
}

.faq {
  b {
    font-size: 1.1rem;
  }
  p {
    margin-top: 0;
  }
}
